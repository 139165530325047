import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { orange, white } from "@material-ui/core/colors";
import { useStateValue } from "./StateProvider";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "transparent",
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    margin: "25px"
  },
  fab: {
    position: "relative",
    zIndex: 2,
    margin: "5px"
  },
  fabOrange: {
    color: orange[500],
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: orange[500],
      color: "white"
    }
  },
  reset: {
    fontSize: "10px"
  }
}));

export default function FloatingActionButtonZoom() {
  const classes = useStyles();
  const [{ zoom }, setZoom] = useStateValue();

  const handleChangeZoomIn = () => {
    if(zoom < 2.2) {
      setZoom({ type: "changeZoom", newZoom: zoom + 0.1 });
    }
  };

  const handleChangeZoomOut = () => {
    if(zoom >= 0.2) {
      setZoom({ type: "changeZoom", newZoom: zoom - 0.1 });
    }
  };

  const handleResetZoom = () => {
    setZoom({ type: "changeZoom", newZoom: 1.0 });
  };

  const fabs = [
    {
      color: "primary",
      className: clsx(classes.fab, classes.fabOrange),
      icon: <AddIcon />,
      label: "Add",
      action: handleChangeZoomIn,
      disabled: zoom >= 2.2 ? true : false
    },
    {
      color: "primary",
      className: clsx(classes.fab, classes.fabOrange),
      icon: <RemoveIcon />,
      label: "RemoveIcon",
      action: handleChangeZoomOut,
      disabled: zoom <= 0.2 ? true : false
    },
    {
      color: "primary",
      className: clsx(classes.fab, classes.fabOrange, classes.reset),
      icon: "Reset",
      label: "Reset",
      action: handleResetZoom
    }
  ];

  return (
    <div className={classes.root}>
      {fabs.map((fab, index) => (
        <Fab
          key={index}
          onClick={fab.action}
          aria-label={fab.label}
          className={fab.className}
          color={fab.color}
          disabled={fab.disabled}
        >
          {fab.icon}
        </Fab>
      ))}
    </div>
  );
}
