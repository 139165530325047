import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useStateValue } from './StateProvider';

const useStyles = makeStyles({
  header: {
    position: 'fixed',
    top: 0,
    width: '100%',
    height: '100px',
    zIndex: '2',
    ['@media (max-width:760px)']: {
      zIndex: '3',
    },
  },
  containerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 15%',
    ['@media (max-width:760px)']: {
      padding: '0 5%',
    },
  },
  containerHeaderImage: {
    width: '150px',
    height: '100px',
    backgroundColor: '#ffffff',
    backgroundImage:
      'url(https://res.cloudinary.com/als-media/image/upload/v1578045340/alsLogo_yvzwim.jpg)',
    backgroundSize: '80%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    ['@media (max-width:760px)']: {
      width: '110px',
    },
  },
  btnContact: {
    marginRight: '10px',
    ['@media (max-width:760px)']: {
      marginRight: '10px',
    },
  },
  btnAlsSite: {
    ['@media (max-width:760px)']: {
      display: 'none',
    },
  },
});

const Header = (_) => {
  let classes = useStyles();
  const [{ showPopup }, dispatchPopup] = useStateValue();

  return (
    <div className={classes.header}>
      <div className={classes.containerHeader}>
        <a href="https://www.als.nl/">
          <div className={classes.containerHeaderImage}></div>
        </a>
        <div>
          <a>
            <button
              onClick={() =>
                dispatchPopup({
                  type: 'togglePopup',
                  currentShowPopup: showPopup,
                })
              }
              className={`${classes.btnContact} btn-default`}>
              Contact
            </button>
          </a>
          <a href="https://www.als.nl/bedrijven/puzzel/">
            <button className={`${classes.btnAlsSite} btn-default`}>
              <span>Naar als.nl</span>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
