import React, { useEffect, useState } from "react";
import { useAsync } from "react-async";
import { makeStyles } from "@material-ui/core/styles";
import { useStateValue } from "./StateProvider";
import { useScrollYPosition } from "react-use-scroll-position";

const toJson = async (url) => {
  let result = await fetch(url);
  return await result.json();
};

const asyncDataFn = async (_) => {
  try {
    let allPromises = [];

    allPromises.push(
      toJson(`${process.env.REACT_APP_STRAPI_URL}/scrollteksts`)
    );

    allPromises.push(
      toJson(`${process.env.REACT_APP_STRAPI_URL}/achtergrondafbeeldings`)
    );

    let allResults = await Promise.all(allPromises);

    return {
      scrollData: allResults[0],
      backgroundData: allResults[1],
    };
  } catch (error) {
    throw new error(error);
  }
};

const useStyles = makeStyles({
  backgroundStyle: {
    backgroundImage: (props) => `${props.backgroundUrlDesktop}`,
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    height: "100vh",
    width: "100vw",
    position: "fixed",
    zIndex: "-1",
    top: 0,
    ["@media (max-width:760px)"]: {
      backgroundImage: (props) => `${props.backgroundUrlMobile}`,
      backgroundAttachment: "scroll",
    },
  },
  scrollerContainer: {
    marginLeft: "15%",
    padding: "0px 10px",
    position: "relative",
    display: "block",
    ["@media (max-width:760px)"]: {
      paddingRight: "25px",
    },
  },
  nextSection: {
    position: "fixed",
    top: "60vh",
    left: "50vw",
    zIndex: "4",
  },
  btnNextSection: {
    backgroundImage:
      "url(https://res.cloudinary.com/als-media/image/upload/v1578052931/nextSection_n6uixp.png)",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    border: "none",
    backgroundColor: "transparent",
    fontSize: "50px",
    width: "80px",
    height: "70px",
    outline: "none",
    zIndex: "2",
    cursor: "pointer",
    ["@media (max-width:760px)"]: {
      display: "none",
    },
  },
  section: {
    display: "flex",
    alignItems: "flex-end",
    height: "400px",
    paddingTop: "100px",
    paddingBottom: "100px",
    maxWidth: "700px",
    marginBottom: "10%",
    fontSize: "16px",
    lineHeight: "2",
    letterSpacing: "0px",
    fontWeight: "300",
    fontStyle: "normal",
    color: "rgb(255, 255, 255)",
    ["@media (max-width:760px)"]: {
      height: "unset",
      alignItems: "flex-start",
    },
    ["@media (max-width:369px)"]: {
      fontSize: "14px!important",
      paddingBottom: "0"
    },
    ["@media (min-width:760px)"]: {
      "&:last-child": {
        height: "100vh",
        alignItems: "center",
        paddingTop: "0px",
        marginTop: "0px",
        marginBottom: "0px",
      },
    },
  },
  grid: {
    marginLeft: "-50px",
    marginTop: "-150px",
    position: "fixed",
    height: "100vh",
    width: "100vw",
    display: "grid",
    gridTemplateColumns: "repeat(20, 198.7px)",
    gridTemplateRows: "repeat(20, 198.7px)",
    ["@media (max-width:760px)"]: {
      gridTemplateColumns: "repeat(20, 90px)",
      gridTemplateRows: "repeat(20, 90px)",
    },
  },
  gridItem: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    "-webkit-transition": "opacity 1s ease-in-out",
    transition: "opacity 1s ease-in-out",
    opacity: "1",
  },
  verRow: {
    backgroundImage:
      "url(https://res.cloudinary.com/als-media/image/upload/v1578057657/verticalPiece_ktoxas.png)",
    margin: "-53.3px -12.7px",
    ["@media (max-width:760px)"]: {
      margin: "-24.1px -5.8px",
    },
    "&:nth-child(odd)": {
      backgroundImage:
        "url(https://res.cloudinary.com/als-media/image/upload/v1578057657/horizontalPiece_irywox.png)",
      margin: "-12.7px -53.3px",
      ["@media (max-width:760px)"]: {
        margin: "-5.8px -24.1px",
      },
    },
  },
  horRow: {
    backgroundImage:
      "url(https://res.cloudinary.com/als-media/image/upload/v1578057657/horizontalPiece_irywox.png)",
    margin: "-12.7px -53.3px",
    ["@media (max-width:760px)"]: {
      margin: "-5.8px -24.1px",
    },

    "&:nth-child(odd)": {
      backgroundImage:
        "url(https://res.cloudinary.com/als-media/image/upload/v1578057657/verticalPiece_ktoxas.png)",
      margin: "-53.3px -12.7px",
      ["@media (max-width:760px)"]: {
        margin: "-24.1px -5.8px",
      },
    },
  },
  noPiece: {
    webkitTransition: "opacity 1s ease-in-out",
    transition: "opacity 1s ease-in-out",
    opacity: "0",
  },
  "@global": {
    ".fadeOut": {
      ["@media (max-width:760px)"]: {
        opacity: "1!important",
      },
      ["@media (max-height:650px)"]: {
        opacity: "1!important",
      },
    },
  },
});

const ScrollerData = (_) => {
  const { data, error, isPending } = useAsync({ promiseFn: asyncDataFn });

  // const classes = useStyles(props);

  if (isPending) return "Loading...";
  if (error) return `Something went wrong: ${error.message}`;
  if (data) {
    return <Scroller data={data} />;
  }
};

const fadeOnScroll = () => {
  // Text fading in/out
  const fadeOut = document.getElementsByClassName("fadeOut");
  [].forEach.call(fadeOut, function (textToFade) {
    const topOfText = textToFade.offsetTop;
    let windowHeight = window.innerHeight;

    if (topOfText - window.scrollY < 150) {
      textToFade.style.opacity = (topOfText - window.scrollY) / 150;
    } else if (
      windowHeight + window.scrollY > topOfText &&
      windowHeight + window.scrollY < topOfText + 500
    ) {
      var op;
      if (windowHeight + window.scrollY < topOfText + 350) {
        op = 0;
      } else {
        op = (windowHeight + window.scrollY - (topOfText + 350)) / 150;
      }

      textToFade.style.opacity = op;
    } else {
      textToFade.style.opacity = 1;
    }
  });
};

const removePuzzlePieces = (scrollY) => {
  let puzzleProgress = 0;

  if (scrollY > 1240) {
    puzzleProgress = 15;
  } else if (scrollY > 1160) {
    puzzleProgress = 14;
  } else if (scrollY > 1080) {
    puzzleProgress = 13;
  } else if (scrollY > 1000) {
    puzzleProgress = 12;
  } else if (scrollY > 920) {
    puzzleProgress = 11;
  } else if (scrollY > 840) {
    puzzleProgress = 10;
  } else if (scrollY > 760) {
    puzzleProgress = 9;
  } else if (scrollY > 680) {
    puzzleProgress = 8;
  } else if (scrollY > 600) {
    puzzleProgress = 7;
  } else if (scrollY > 520) {
    puzzleProgress = 6;
  } else if (scrollY > 440) {
    puzzleProgress = 5;
  } else if (scrollY > 360) {
    puzzleProgress = 4;
  } else if (scrollY > 280) {
    puzzleProgress = 3;
  } else if (scrollY > 200) {
    puzzleProgress = 2;
  } else if (scrollY > 120) {
    puzzleProgress = 1;
  }

  return puzzleProgress;
};

const getCurrentSection = (scrollY) => {
  let sections = document.getElementsByClassName("text");
  let activeSectionNr;

  [].forEach.call(sections, function (section, index) {
    section.classList.remove("active");
    if (section.offsetTop <= scrollY) {
      activeSectionNr = index;
    }
  });
  return activeSectionNr;
};

const setNextSection = (current) => {
  if (current === undefined) {
    current = 0;
  }
  return current + 1;
};

const makePuzzlePiece = (puzzleProgress, requiredProgress, horVer, classes) => {
  if (horVer === "hor") {
    if (puzzleProgress > requiredProgress) {
      return (
        <div
          className={`${classes.gridItem} ${classes.horRow} ${classes.noPiece}`}
        ></div>
      );
    } else {
      return <div className={`${classes.gridItem} ${classes.horRow}`}></div>;
    }
  } else if (horVer === "ver") {
    if (puzzleProgress > requiredProgress) {
      return (
        <div
          className={`${classes.gridItem} ${classes.verRow} ${classes.noPiece}`}
        ></div>
      );
    } else {
      return <div className={`${classes.gridItem} ${classes.verRow}`}></div>;
    }
  }
};

const Grid = (_) => {
  let [puzzleProgress, dispatch] = useState(0);
  let scrollY = useScrollYPosition();
  let classes = useStyles();

  useEffect(() => {
    if (puzzleProgress.progress !== removePuzzlePieces(scrollY)) {
      dispatch({
        progress: removePuzzlePieces(scrollY),
      });
    }
  }, [scrollY, puzzleProgress.progress]);

  return (
    <div className={classes.grid} id="containerGridSysteem">
      {/* row 1 */}
      {makePuzzlePiece(puzzleProgress.progress, 14, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 12, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 3, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 12, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 1, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 13, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 3, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 12, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 3, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 12, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 1, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 13, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 3, "hor", classes)}

      {/* row 2 */}
      {makePuzzlePiece(puzzleProgress.progress, 14, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 5, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 7, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 0, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 4, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 9, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 3, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 6, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 7, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 5, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 7, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 0, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 4, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 9, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 3, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 6, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 7, "ver", classes)}

      {/* row 3 */}
      {makePuzzlePiece(puzzleProgress.progress, 14, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 2, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 4, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 11, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 5, "hor", classes)}
      <div
        className={(classes.gridItem, classes.horRow, classes.noPiece)}
      ></div>
      {makePuzzlePiece(puzzleProgress.progress, 5, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 10, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 1, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 0, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 2, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 4, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 11, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 5, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 1, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 5, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 10, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 1, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 0, "hor", classes)}

      {/* row 4 */}
      {makePuzzlePiece(puzzleProgress.progress, 11, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 0, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 9, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 4, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 7, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 13, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 9, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 11, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 0, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 9, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 4, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 7, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 13, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 9, "ver", classes)}

      {/* row 5 */}
      {makePuzzlePiece(puzzleProgress.progress, 14, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 11, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 1, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 12, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 10, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 2, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 6, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 0, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 11, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 1, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 12, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 10, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 2, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 6, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 0, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "hor", classes)}

      {/* row 6 */}
      {makePuzzlePiece(puzzleProgress.progress, 14, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 5, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 7, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 13, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 3, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 10, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 9, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 1, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 5, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 7, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 13, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 3, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 10, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 9, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 1, "ver", classes)}

      {/* row 7 */}
      {makePuzzlePiece(puzzleProgress.progress, 14, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 12, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 3, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 12, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 1, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 13, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 3, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 12, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 3, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 12, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 1, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 13, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 3, "hor", classes)}

      {/* row 8 */}
      {makePuzzlePiece(puzzleProgress.progress, 11, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 0, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 9, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 4, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 7, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 13, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 9, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 11, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 0, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 9, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 4, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 7, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 13, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 9, "ver", classes)}

      {/* row 9 */}
      {makePuzzlePiece(puzzleProgress.progress, 14, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 12, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 3, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 12, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 1, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 13, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 3, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 12, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 3, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 12, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 1, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 13, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 3, "hor", classes)}

      {/* row 10 */}
      {makePuzzlePiece(puzzleProgress.progress, 14, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 5, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 7, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 0, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 4, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 9, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 3, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 6, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 7, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 5, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 7, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 0, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 4, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 9, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 3, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 6, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 7, "ver", classes)}

      {/* row 11 */}
      {makePuzzlePiece(puzzleProgress.progress, 14, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 2, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 4, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 11, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 5, "hor", classes)}
      <div
        className={(classes.gridItem, classes.horRow, classes.noPiece)}
      ></div>
      {makePuzzlePiece(puzzleProgress.progress, 5, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 10, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 1, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 0, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 2, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 4, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 11, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 5, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 1, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 5, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 10, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 1, "hor", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 0, "hor", classes)}

      {/* row 12 */}
      {makePuzzlePiece(puzzleProgress.progress, 11, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 0, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 9, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 4, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 7, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 13, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 9, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 11, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 14, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 0, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 9, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 8, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 4, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 7, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 13, "ver", classes)}
      {makePuzzlePiece(puzzleProgress.progress, 9, "ver", classes)}
    </div>
  );
};

const Scroller = (props) => {
  let desktopBackground = [];
  let mobileBackground = [];

  // Select random Background
  for (let i = 0; i < props.data.backgroundData.length; i++) {
    props.data.backgroundData[i].Desktop === true &&
      desktopBackground.push(props.data.backgroundData[i]);
    props.data.backgroundData[i].Mobiel === true &&
      mobileBackground.push(props.data.backgroundData[i]);
  }

  let randomDesktop =
    desktopBackground[Math.floor(Math.random() * desktopBackground.length)];
  let randomMobile =
    mobileBackground[Math.floor(Math.random() * mobileBackground.length)];

  let propsUrl = {
    backgroundUrlDesktop:
      desktopBackground !== []
        ? `url(${randomDesktop.Afbeelding.url})`
        : "white",
    backgroundUrlMobile:
      mobileBackground !== [] ? `url(${randomMobile.Afbeelding.url})` : "white",
  };
  let classes = useStyles(propsUrl);

  return (
    <React.Fragment>
      <div className={classes.backgroundStyle} id="backgroundImage">
      </div>
      <Grid />
      <div className={classes.scrollerContainer} id="containerScroller">
        <ScrollerContent data={props.data} />
      </div>
    </React.Fragment>
  );
};

const ScrollerContent = (props) => {
  const [{ currentSection }, dispatch] = useStateValue();
  let classes = useStyles();

  let scrollY = useScrollYPosition();

  useEffect(() => {
    fadeOnScroll();

    // After end of scroll container, remove navigation, next-section and grid
    let endOfScroller =
      scrollY + window.innerHeight >
      document.getElementById("containerScroller").offsetHeight - 400;

    document.getElementById("containerGridSysteem").style.display =
      scrollY + window.innerHeight >
      document.getElementById("containerScroller").offsetHeight + 200
        ? "none"
        : "grid";

    let opacityNavNext =
      (document.getElementById("containerScroller").offsetHeight -
        (scrollY + window.innerHeight + 50)) /
      200;

    document.getElementById("navigationContainer").style.display =
      opacityNavNext <= 0 ? "none" : "flex";

    document.getElementById("nextSectionLink").style.display =
      opacityNavNext <= 0 ? "none" : "inline-block";

    if (endOfScroller) {
      document.getElementById(
        "navigationContainer"
      ).style.opacity = opacityNavNext;
      document.getElementById("nextSectionLink").style.opacity = opacityNavNext;
    } else {
      document.getElementById("navigationContainer").style.opacity = 1;
      document.getElementById("nextSectionLink").style.opacity = 1;
    }

    // Dispatch new current section if current section changes
    if (currentSection !== getCurrentSection(scrollY)) {
      dispatch({
        type: "changeCurrentSection",
        changeCurrentSection: getCurrentSection(scrollY),
      });
    }
  }, [scrollY, currentSection, dispatch]);

  return (
    <React.Fragment>
      <a
        className={classes.nextSection}
        href={`#text-${setNextSection(currentSection)}`}
        id="nextSectionLink"
      >
        <button className={classes.btnNextSection}></button>
      </a>
      {props.data.scrollData.map((scrolltekst, index) => {
        let textId = `text-${index}`;
        return (
          <section
            className={`text ${classes.section}`}
            id={textId}
            key={index}
          >
            <div className="fadeOut">
              <h1>{scrolltekst.Titel}</h1>
              <p>{scrolltekst.Inhoud}</p>
            </div>
          </section>

        );
      })}
    </React.Fragment>
  );
};

export default ScrollerData;
