import React from 'react';
import { useAsync } from 'react-async';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as PieceHorizontal } from './svg/piece-1x-horizontal.svg';
import { ReactComponent as PieceVertical } from './svg/piece-1x-vertical.svg';
import { useStateValue } from './StateProvider';
import ZoomButton from './ZoomButton';

const toJson = async (url) => {
  let result = await fetch(url);
  return await result.json();
};

const asyncDataFn = async (_) => {
  try {
    let allPromises = [];

    allPromises.push(toJson(`${process.env.REACT_APP_STRAPI_URL}/bedrijfs`));

    let allResults = await Promise.all(allPromises);

    return allResults[0];
  } catch (error) {
    throw new error(error);
  }
};

const useStyles = makeStyles({
  puzzleContainer: {
    overflow: 'scroll',
    position: 'relative',
    paddingBottom: '170px',
    backgroundColor: '#FF8D20',
    padding: '100px',
    ['@media (max-width:760px)']: {
      padding: '50px 150px 0px 150px',
    },
  },

  gridContainer: {
    width: (props) => `calc(${Math.ceil(Math.sqrt(props.total / 4))} * ${props.gridsize}px)`,
    margin: '0 auto 100px',
    display: 'grid',
    gridTemplateColumns: (props) =>
      `repeat(${Math.ceil(Math.sqrt(props.total / 4))}, ${props.gridsize}px)`,
    gridTemplateRows: (props) =>
      `repeat(${Math.ceil(Math.sqrt(props.total / 4))}, ${props.gridsize}px)`,
  },
  pieceVertical: {
    display: 'flex',
    justifyContent: 'center',
  },
  pieceImage: {
    width: (props) => `${props.piecesvgsize}px`,
    height: (props) => `${props.piecesvgsize}px`,
    display: 'grid',
    alignSelf: 'center',
    overflow: 'visible',
    position: 'absolute',
  },
  pieceHorizontal: {
    display: 'flex',
    justifyContent: 'center',
  },
  fourGrid: {
    display: 'grid',
    gridTemplateColumns: (props) => `repeat(2, ${props.piecesize}px)`,
    gridTemplateRows: (props) => `repeat(2, ${props.piecesize}px)`,
  },
  logo: {
    objectFit: 'contain',
    width: '40%',
    height: '40%',
    display: 'flex',
    alignSelf: 'center',
    zIndex: '0',
  },
  companyUrl: {
    display: 'grid',
    transition: 'transform 0.3s, filter 0.3s',
    '&:hover': {
      transform: 'scale(1.1)',
      filter: 'drop-shadow(0px 0px 10px black)',
      zIndex: 100,
    },
  },
});

const VerticalPieceContainer = (props) => {
  let classes = useStyles(props);
  let logo = props.companyLogo
    ? props.companyLogo
    : 'https://res.cloudinary.com/als-media/image/upload/v1579617323/kruiv0dv2pkkrc5m8bn0.png';
  return (
    <a href={props.companyUrl} target="_blamk" className={classes.companyUrl}>
      <div className={classes.pieceVertical}>
        <PieceVertical className={classes.pieceImage} />
        <img src={logo} className={classes.logo} />
      </div>
    </a>
  );
};

const HorizontalPieceContainer = (props) => {
  let classes = useStyles(props);
  let logo = props.companyLogo
    ? props.companyLogo
    : 'https://res.cloudinary.com/als-media/image/upload/v1579617323/kruiv0dv2pkkrc5m8bn0.png';
  return (
    <a href={props.companyUrl} target="_blamk" className={classes.companyUrl}>
      <div className={classes.pieceHorizontal}>
        <PieceHorizontal className={classes.pieceImage} />
        <img src={logo} className={classes.logo} />
      </div>
    </a>
  );
};

const RenderFourGrid = (props) => {
  let classes = useStyles(props);

  return (
    <div
      className={`${classes.fourGrid} puzzlePiecesContainer`}
      key={`FourGrid-${props.companyid}-${props.gridNr}`}>
      <VerticalPieceContainer
        name={props.pieces[0].Naam}
        companyUrl={props.pieces[0].Website}
        companyLogo={props.pieces[0].Logo ? props.pieces[0].Logo.url : undefined}
        piecesize={props.piecesize}
        piecesvgsize={props.piecesvgsize}
      />

      {props.pieces.length > 3 && (
        <HorizontalPieceContainer
          name={props.pieces[3].Naam}
          companyUrl={props.pieces[3].Website}
          companyLogo={props.pieces[3].Logo ? props.pieces[3].Logo.url : undefined}
          piecesize={props.piecesize}
          piecesvgsize={props.piecesvgsize}
        />
      )}

      {props.pieces.length > 1 && (
        <HorizontalPieceContainer
          name={props.pieces[1].Naam}
          companyUrl={props.pieces[1].Website}
          companyLogo={props.pieces[1].Logo ? props.pieces[1].Logo.url : undefined}
          piecesize={props.piecesize}
          piecesvgsize={props.piecesvgsize}
        />
      )}
      {props.pieces.length > 2 && (
        <VerticalPieceContainer
          name={props.pieces[2].Naam}
          companyUrl={props.pieces[2].Website}
          companyLogo={props.pieces[2].Logo ? props.pieces[2].Logo.url : undefined}
          piecesize={props.piecesize}
          piecesvgsize={props.piecesvgsize}
        />
      )}
    </div>
  );
};

const Grid = (props) => {
  let classes = useStyles(props);
  let orderCompanies = props.companies.sort((a, b) => {
    if (a.Volgorde === null) {
      a.Volgorde = 999999999;
    } else if (b.Volgorde === null) {
      b.Volgorde = 999999999;
    }
    return a.Volgorde > b.Volgorde ? 1 : b.Volgorde > a.Volgorde ? -1 : 0;
  });

  const puzzleToArray = (orderCompanies) => {
    let allPuzzlePieces = [];
    orderCompanies.map((company) => {
      for (var i = 0; i < company.aantal_puzzelstukjes; i++) {
        allPuzzlePieces.push(company);
      }
    });
    let fourPieces = [];
    let index = 0;
    for (index = 0; index < allPuzzlePieces.length; index += 4) {
      let gridPiece = allPuzzlePieces.slice(index, index + 4);
      fourPieces.push(gridPiece);
    }
    return fourPieces;
  };

  let puzzleArray = puzzleToArray(orderCompanies);
  return (
    <div className={classes.puzzleContainer}>
      <div className={classes.gridContainer} id="gridPuzzle" gridsize={props.gridsize}>
        {puzzleArray.map((gridItem, index) => {
          return (
            <React.Fragment key={`Fragment-${index}`}>
              <RenderFourGrid
                gridNr={index}
                piecesize={props.piecesize}
                piecesvgsize={props.piecesvgsize}
                pieces={gridItem}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

const CompanyPuzzle = (props) => {
  const { data, error, isPending } = useAsync({ promiseFn: asyncDataFn });
  const [{ zoom }, dispatch] = useStateValue();

  let gridsize = 200 * zoom;
  let piecesize = 100 * zoom;
  let piecesvgsize = 160 * zoom;

  let totalCompanies = 0;

  if (isPending) return 'Loading...';
  if (error) return `Something went wrong: ${error.message}`;
  if (data) {
    data.map((item) => {
      totalCompanies = totalCompanies + item.aantal_puzzelstukjes;
    });

    return (
      <React.Fragment>
        <ZoomButton />

        <Grid
          companies={data}
          total={totalCompanies}
          gridsize={gridsize}
          piecesvgsize={piecesvgsize}
          piecesize={piecesize}
        />
      </React.Fragment>
    );
  }
};

export default CompanyPuzzle;
