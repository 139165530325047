import React from "react";
import { useAsync } from "react-async";
import Footer from "./Footer";
import Header from "./Header";
import Navigation from "./Navigation";
import Scroller from "./Scroller";
import { StateProvider } from "./StateProvider";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
  CssBaseline,
} from "@material-ui/core";
import CompanyPuzzle from "./CompanyPuzzle";
import TagManager from "react-gtm-module";
import InterstateLight from "./fonts/InterstateLight.ttf";
import Interstate from "./fonts/InterstateBold.otf";

const interstateLight = {
  fontFamily: "Interstate-Light",
  src: `url(${InterstateLight})`,
};

const interstate = {
  fontFamily: "Interstate",
  src: `url(${Interstate})`,
};

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

TagManager.initialize(tagManagerArgs);

const asyncFn = async ({ signal }) => {
  const res = await fetch(`${process.env.REACT_APP_STRAPI_URL}/bedrijfs`, {
    signal,
  });
  if (!res.ok) throw new Error(res.statusText);
  return res.json();
};

const reducer = (state, action) => {
  switch (action.type) {
    case "changeCurrentSection":
      return {
        ...state,
        currentSection: action.changeCurrentSection,
      };
    case "togglePopup":
      return {
        ...state,
        showPopup: !action.currentShowPopup,
      };
    case "changeOpmerkingen":
      return {
        ...state,
        opmerkingen: {
          input:
            action.newInput !== undefined
              ? action.newInput
              : state.opmerkingen.input,
        },
      };
    case "changeBedrijfsnaam":
      return {
        ...state,
        bedrijfsnaam: {
          input:
            action.newInput !== undefined
              ? action.newInput
              : state.bedrijfsnaam.input,
          error:
            action.newError !== undefined
              ? action.newError
              : state.bedrijfsnaam.error,
        },
      };
    case "changeContactpersoon":
      return {
        ...state,
        contactpersoon: {
          input:
            action.newInput !== undefined
              ? action.newInput
              : state.contactpersoon.input,
          error:
            action.newError !== undefined
              ? action.newError
              : state.contactpersoon.error,
        },
      };
    case "changeMailadres":
      return {
        ...state,
        mailadres: {
          input:
            action.newInput !== undefined
              ? action.newInput
              : state.mailadres.input,
          error:
            action.newError !== undefined
              ? action.newError
              : state.mailadres.error,
          errorType:
            action.newErrorType !== undefined
              ? action.newErrorType
              : state.mailadres.errorType,
        },
      };
    case "changeTelefoonnummer":
      return {
        ...state,
        telefoonnummer: {
          input:
            action.newInput !== undefined
              ? action.newInput
              : state.telefoonnummer.input,
          error:
            action.newError !== undefined
              ? action.newError
              : state.telefoonnummer.error,
        },
      };
    case "changeZoom":
      return {
        ...state,
        zoom: action.newZoom,
      };
    default:
      return state;
  }
};

const useStyles = makeStyles({
  "@global": {
    "*, *::before, *::after": {
      boxSizing: "unset",
    },
    html: {
      scrollBehavior: "smooth",
      boxSize: "inherit",
    },
    ".btn-default": {
      marginTop: "20px",
      backgroundColor: "transparent",
      padding: "10px 18px",
      border: "1px solid #ffffff",
      color: "#ffffff",
      fontSize: "1.2em",
      fontWeight: "600",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#ff8d20",
        color: "white",
        border: "1px solid #ff8d20",
      },
    },
    h1: {
      marginTop: "0",
    },
    body: {
      margin: "0",
    },
  },
});

const initialState = {
  currentSection: 0,
  showPopup: false,
  zoom: 1.0,

  opmerkingen: { input: "" },
  bedrijfsnaam: { input: "", error: false },
  contactpersoon: { input: "", error: false },
  mailadres: { input: "", error: false },
  telefoonnummer: { input: "", error: false },
};

const theme = createMuiTheme({
  palette: {
    primary: {
      500: "#ff8d20",
    },
  },
  status: {
    danger: "orange",
  },
  typography: {
    fontFamily: "Interstate-Light, Interstate",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [interstate, interstateLight],
      },
    },
  },
});

function App() {
  const { data, error, isPending } = useAsync({ promiseFn: asyncFn });
  const classes = useStyles();

  if (isPending) return "Loading...";
  if (error) return `Something went wrong: ${error.message}`;
  if (data) {
    return (
      <StateProvider initialState={initialState} reducer={reducer}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Header />
          <Navigation />
          <Scroller />
          <CompanyPuzzle />
          <Footer />
        </ThemeProvider>
      </StateProvider>
    );
  }
}

export default App;
