import React, { useEffect } from "react";
import { useStateValue } from "./StateProvider";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "./Popup";

const useStyles = makeStyles({
  navigationContainer: {
    height: "100%",
    width: "190px",
    position: "fixed",
    zIndex: "1",
    top: "0",
    left: "0",
    overflowX: "initial",
    paddingTop: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    ["@media (max-width:760px)"]: {
      width: "50px"
    }
  },
  containerBtnProgress: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "6px 8px 6px 16px",
    textDecoration: "none",
    fontSize: "25px"
  },
  btnProgress: {
    width: "10px",
    minWidth: "10px",
    height: "10px",
    border: "1px solid #ff8d20",
    borderRadius: "50%",
    backgroundColor: "none"
  },
  btnProgressActive: {
    backgroundColor: "#ff8d20"
  },
  btnPopup: {
    borderColor: "#ff8d20!important",
    transform: "rotate(-90deg) translate(-80px, 4px)",
    fontSize: "10px!important",
    ["@media (max-width:760px)"]: {
      position: "fixed",
      bottom: "0",
      width: "100vw",
      height: "10vh",
      transform: "rotate(0)",
      borderRadius: "0!important",
      backgroundColor: "#ff8d20!important",
      fontSize: "20px!important",
      fontFamily:
        'Interstate, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
      fontWeight: "bold!important"
    }
  }
});

const Navigation = _ => {
  const [{ showPopup }, dispatchPopup] = useStateValue();
  const [{ currentSection }] = useStateValue();
  let classes = useStyles();

  return (
    <React.Fragment>
      <nav className={classes.navigationContainer} id="navigationContainer">
        <a className={classes.containerBtnProgress} href="#text-0">
          <div
            className={`${classes.btnProgress} ${
              currentSection === 0 ? classes.btnProgressActive : ""
            }`}
            id="nav-0"
          ></div>
        </a>
        <a className={classes.containerBtnProgress} href="#text-1">
          <div
            className={`${classes.btnProgress} ${
              currentSection === 1 ? classes.btnProgressActive : ""
            }`}
            id="nav-1"
          ></div>
        </a>
        <a className={classes.containerBtnProgress} href="#text-2">
          <div
            className={`${classes.btnProgress} ${
              currentSection > 1 ? classes.btnProgressActive : ""
            }`}
            id="nav-2"
          ></div>
        </a>
        <button
          className={`btn-default ${classes.btnPopup}`}
          onClick={() =>
            dispatchPopup({
              type: "togglePopup",
              currentShowPopup: showPopup
            })
          }
        >
          Ik puzzel mee
        </button>
      </nav>

      {showPopup && <Popup />}
    </React.Fragment>
  );
};

export default Navigation;
