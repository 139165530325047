import React, { useState } from "react";
import { useStateValue } from "./StateProvider";
import {
  makeStyles,
  TextField,
  TextareaAutosize,
  Button
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import useForm from "./useForm";

const useStyles = makeStyles(theme => ({
  overlay: {
    position: "fixed",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "4"
  },
  popup: {
    position: "fixed",
    width: "600px",
    height: "auto",
    left: "calc(50% - 371px)",
    top: "calc(50% - 227px)",
    background: "white",
    zIndex: "5",
    padding: "70px 70px 10px 70px",
    border: "1px solid #FF8D20",
    ["@media (max-width:760px)"]: {
      left: "0",
      width: "calc(100vw - 42px)",
      padding: "40px 10px 10px 10px",
      margin: "0 10px"
    },
    ["@media (max-width:420px) and (max-height:675px)"]: {
      bottom: "10px",
      top: "10px",
      overflowY: "auto"
    },
    ["@media (max-width:320px)"]: {
      paddingTop: "10px"
    }
  },
  puzzlePieceContact: {
    position: "absolute",
    width: "160px",
    height: "auto",
    top: "-75px",
    left: "30px",
    ["@media (max-width:420px) and (max-height:675px)"]: {
      display: "none"
    }
  },
  popupTitle: {
    fontFamily:
      'Interstate, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    fontWeight: "Bold",
    ["@media (max-width:420px) and (max-height:675px)"]: {
      marginTop: "5px"
    }
  },
  popupForm: {
    display: "flex",
    flexDirection: "column"
  },
  popupFormRow: {
    display: "flex",
    justifyContent: "space-between",
    "& div" : {
      display: "flex",
      flexGrow: 1
    },
    "& > div:first-child": {
      marginRight: "4px"
    },
    "& > div:last-child": {
      marginLeft: "4px"
    }
  },
  popupFieldOpmerkingen: {
    width: "99%!important",
    borderColor: "#ff8d20",
    "&:focus": {
      outlineColor: "#ff8d20"
    }
  },
  popupField: {
    display: "flex",
    flexGrow: 1,
  },
  popupMessage: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  containerBtnPopupSubmit: {
    textAlign: "center",
    ["@media (max-width:760px)"]: {
      marginTop: "7px"
    }
  },
  btnPopupSubmit: {
    backgroundColor: "#FF8D20!important",
    width: "150px",
    fontSize: "15px!important",
    marginTop: "0!important",
    border: "none!important"
  },
  btnClosePopup: {
    position: "absolute",
    right: "15px",
    top: "15px",
    backgroundColor: "#FF8D20",
    cursor: "pointer",
    color: "#fff",
    fontSize: "25px",
    border: "none!important",
    padding: "1px 7px 2px"
  },
  "@global": {
    "form div fieldset": {
      borderColor: "#ff8d20!important"
    },
    "label.MuiFormLabel-root": {
      ["@media (max-width:760px)"]: {
        fontSize: "0.8rem!important",
        width: "calc(100% - 14px)"
      }
    },
    ".MuiFormHelperText-contained": {
      ["@media (max-width:760px)"]: {
        margin: "1px 14px -10px 0"
      }
    },
    ".MuiFormControl-marginNormal": {
      ["@media (max-width:760px)"]: {
        marginTop: "8px"
      }
    }
  }
}));

const Popup = _ => {
  const [{ showPopup }, dispatchPopup] = useStateValue();
  const { values, handleChange, handleSubmit } = useForm();

  let classes = useStyles();

  const closePopup = _ => {
    dispatchPopup({
      type: "togglePopup",
      currentShowPopup: showPopup
    });
  };

  const sentMessage = _ => {
    if (values.sent) {
      return (
        <React.Fragment>
          <h2 className={classes.popupTitle}>
            Hartelijk dank voor je interesse in deelname!
          </h2>
          <p className={classes.popupMessage}>
            Wij nemen zo snel mogelijk contact op.
          </p>
          <Button
            variant="contained"
            className={`${classes.btnPopupSubmit} btn-default`}
            onClick={closePopup}
          >
            Sluit
          </Button>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h2 className={classes.popupTitle}>
            Puzzel mee voor een toekomst zonder ALS
          </h2>
          <p>
            Wij gaan graag een gesprek met u aan om te kijken hoe u ook een
            stukje kunt bijdragen aan onze puzzel naar een oplossing tegen ALS.
          </p>

          <ValidatorForm
            className={classes.container}
            onError={errors => console.log(errors)}
            onSubmit={handleSubmit}
          >
            <TextValidator
              id="message"
              label="Opmerkingen"
              value={values.message}
              multiline
              rows="3"
              className={classes.popupFieldOpmerkingen}
              margin="normal"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              placeholder="Opmerkingen"
            />
            <div className={classes.popupFormRow}>
              <TextValidator
                id="company"
                label="Naam bedrijf*"
                className={classes.popupField}
                value={values.company}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                fullWidth
                validators={["required"]}
                errorMessages={["Dit is een verplicht veld"]}
              />
              <TextValidator
                id="name"
                label="Naam contactpersoon*"
                className={classes.popupField}
                value={values.name}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                fullWidth
                validators={["required"]}
                errorMessages={["Dit is een verplicht veld"]}
              />
            </div>
            <div className={classes.popupFormRow}>
              <TextValidator
                id="email"
                label="E-mailadres*"
                type="email"
                value={values.email}
                className={classes.popupField}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                fullWidth
                validators={["required", "isEmail"]}
                errorMessages={[
                  "Dit veld is benodigd.",
                  "Email is niet geldig."
                ]}
              />
              <TextValidator
                id="phone"
                label="Telefoonnummer*"
                className={classes.popupField}
                value={values.phone}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                fullWidth
                validators={["required"]}
                errorMessages={["Dit is een verplicht veld"]}
              />
            </div>
            <div className={classes.containerBtnPopupSubmit}>
              <Button
                variant="contained"
                className={`${classes.btnPopupSubmit} btn-default`}
                type="submit"
              >
                Verstuur
              </Button>
            </div>
          </ValidatorForm>
        </React.Fragment>
      );
    }
  };

  return (
    <React.Fragment>
      <div
        className={classes.overlay}
        onClick={() =>
          dispatchPopup({
            type: "togglePopup",
            currentShowPopup: showPopup
          })
        }
      ></div>
      <div className={classes.popup}>
        <img
          className={classes.puzzlePieceContact}
          src="https://res.cloudinary.com/als-media/image/upload/v1578064474/puzzle_piece_contact_dkgojh.png"
          alt="popupPuzzelStuk"
        />
        {sentMessage()}
        <button
          onClick={() =>
            dispatchPopup({
              type: "togglePopup",
              currentShowPopup: showPopup
            })
          }
          className={classes.btnClosePopup}
        >
          X
        </button>
      </div>
    </React.Fragment>
  );
};

export default Popup;
