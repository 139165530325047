import { useState } from "react";
import axios from "axios";

const useForm = (callback) => {
  const [values, setValues] = useState({
    company: "",
    name: "",
    email: "",
    phone: "",
    message: "",
    sent: false,
  });

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    sendMail(values);
    setValues({ sent: true });
  };

  const handleChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.id]: event.target.value,
    }));
  };

  const sendMail = (values) => {
    axios({
      method: "post",
      url: "/contact/email",
      data: values,
    }).then(
      (response) => {
        console.log(response);
      },
      (reject) => {
        console.log(reject);
      }
    );
  };

  return {
    handleChange,
    handleSubmit,
    values,
    setValues,
  };
};

export default useForm;
