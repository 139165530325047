import React from "react";
import { useStateValue } from "./StateProvider";
import { useAsync } from "react-async";

import { makeStyles } from "@material-ui/core/styles";

const toJson = async url => {
  let result = await fetch(url);
  return await result.json();
};

const asyncDataFn = async _ => {
  try {
    let allPromises = [];

    allPromises.push(toJson(`${process.env.REACT_APP_STRAPI_URL}/quotes`));

    let allResults = await Promise.all(allPromises);

    return {
      quotes: allResults[0]
    };
  } catch (error) {
    throw new error(error);
  }
};

const FooterData = _ => {
  const { data, error, isPending } = useAsync({ promiseFn: asyncDataFn });

  if (isPending) return "Loading...";
  if (error) return `Something went wrong: ${error.message}`;
  if (data) {
    return <Footer data={data} />;
  }
};

const useStyles = makeStyles({
  containerFooter: {
    zIndex: "11",
    backgroundColor: "#fff"
  },
  containerCallToAction: {
    width: "45%",
    margin: "auto",
    padding: "40px",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);",
    "-webkit-transform": "translateY(-150px)",
    transform: "translateY(-150px)",
    textAlign: "center",
    backgroundColor: "white",
    lineHeight: "1.8",
     ["@media (max-width:760px)"]: {
      width: "70vw",
      padding: "40px 20px"
    }
  },
  callToActionTitle: {
    fontWeight: "Bold"
  },
  callToActionText: {
    },
  btnPopup: {
    backgroundColor: "#ff8d20!important",
    borderColor: "#ff8d20!important",
    fontSize: "15px!important",
    padding: "10px 35px!important"
  },
  containerQuote: {
    background:
      "url(https://res.cloudinary.com/als-media/image/upload/v1579008635/background-piece_o9mdkq.png)",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "60%",
    margin: "auto",
    textAlign: "center",
    padding: "80px 0",
    marginTop: "-150px"
  },
  containerQuoteSign: {
    marginBottom: "-50px"
  },
  quoteSign: {
    fontSize: "70px",
    color: "#ff8d20"
  },
  quoteText: {
    fontSize: "20px",
    letterSpacing: "1.5px",
    lineHeight: "1.4",
  },
  quoteNameBedrijf: {
    color: "#ff8d20"
  },
  containerFooterLinks: {
    display: "flex",
    justifyContent: "space-between",
    width: "70%",
    margin: "auto",
    borderTop: "1px solid #ff8d20",
    paddingTop: "20px",
    paddingBottom: "20px",
    ["@media (max-width:760px)"]: {
      width: "100vw",
      paddingBottom: "10px",
      flexDirection: "column"
    }
  },
  containerFooterImages: {
    display: "flex",
    ["@media (max-width:760px)"]: {
      flexDirection: "row-reverse",
      justifyContent: "center"
    }
  },
  containerFooterText: {
    display: "flex",

    ["@media (max-width:760px)"]: {
      justifyContent: "center",
      marginTop: "30px"
    }
  },
  footerLinks: {
    marginLeft: "20px",
    height: "40px",
    fontSize: "16px",
    lineHeight: "2.5",
    textDecoration: "none",
    color: "black",
    ["@media (max-width:760px)"]: {
      marginRight: "10px",
      marginLeft: "10px",
      fontSize: "13px"
    }
  },
  copyright: {
    ["@media (max-width:760px)"]: {
      lineHeight: "3"
    }
  },
  footerTextLinks: {
    ["@media (max-width:760px)"]: {
      color: "#ff8d20"
    }
  }
});

const Footer = props => {
  let classes = useStyles();

  let useQuotes = [];

  // Select random Quote
  for (let i = 0; i < props.data.quotes.length; i++) {
    props.data.quotes[i].In_Gebruik === true &&
      useQuotes.push(props.data.quotes[i]);
  }
  const quote = useQuotes[Math.floor(Math.random() * useQuotes.length)];

  return (
    <div className={classes.containerFooter}>
      <div className={classes.containerCallToAction}>
        <h2 className={classes.callToActionTitle}>Puzzel mee tegen ALS</h2>
        <p className={classes.callToActionText}>
          Wij strijden voor een toekomst zonder ALS, strijdt uw bedrijf met ons
          mee? Als U ook wilt bijdragen aan een ALS-vrije wereld, dan gaan wij
          graag het gesprek met u aan om te kijken hoe u ook een stukje kunt
          bijdragen aan onze puzzel naar een medicijn
        </p>
        <ButtonPopup data={props.data} />
      </div>
      <div className={classes.containerQuote}>
        <div className={classes.containerQuoteSign}>
          <span className={classes.quoteSign}>"</span>
        </div>
        <p className={classes.quoteText}>“{quote.Quote}”</p>
        <span className={classes.quoteNameBedrijf}>
          {quote.Naam} - {quote.Bedrijf}
        </span>
      </div>
      <div className={classes.containerFooterLinks}>
        <div className={classes.containerFooterImages}>
          <a
            href="https://www.myobi.eu/?token=589cd2249932889cccc3e6c9"
            target="_blank"
          >
            <img
              src="https://www.als.nl/wp-content/themes/als/images/privacyseal-logo.jpg"
              alt="privacyseal-logo"
              className={classes.footerLinks}
            />
          </a>
          <img
            src="https://www.als.nl/wp-content/themes/als/images/cbf-new.jpg"
            alt="cbf-logo"
            className={classes.footerLinks}
          />
          <img
            src="https://www.als.nl/wp-content/themes/als/images/anbi-logo.gif"
            alt="anbi-logo"
            className={classes.footerLinks}
          />

          <span className={`${classes.footerLinks} ${classes.copyright}`}>
            &#x24B8; Copyright ALS 2019
          </span>
        </div>
        <div className={classes.containerFooterText}>
          <a
            href="https://www.als.nl/informatie/veelgestelde-vragen/"
            className={`${classes.footerLinks} ${classes.footerTextLinks}`}
          >
            Veelgestelde vragen
          </a>
          <a
            href="https://www.als.nl/informatie/privacybeleid/"
            className={`${classes.footerLinks} ${classes.footerTextLinks}`}
          >
            Privacybeleid
          </a>
          <a
            href="https://www.als.nl/informatie/contact/"
            className={`${classes.footerLinks} ${classes.footerTextLinks}`}
          >
            Contact
          </a>
        </div>
      </div>
    </div>
  );
};

const ButtonPopup = props => {
  const [{ showPopup }, dispatchPopup] = useStateValue();
  let classes = useStyles();

  return (
    <button
      className={`btn-default ${classes.btnPopup}`}
      onClick={() =>
        dispatchPopup({
          type: "togglePopup",
          currentShowPopup: showPopup
        })
      }
    >
      Ik puzzel mee
    </button>
  );
};

export default FooterData;
